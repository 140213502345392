.breadcrumb__list
    display: flex
    gap: 12px

.breadcrumb__item
    display: flex
    gap: 12px
    color: $dark

    a
        color: $dark

    &.--last
        color: $dark
        font-weight: 700

.breadcrumb__list
    margin-bottom: 24px
