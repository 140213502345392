.action-detail
    position: relative

.action-detail__content
    display: flex
    flex-direction: column

.action-detail__tags
    display: flex
    gap: 1rem
    flex-wrap: wrap
    margin-bottom: 3rem

.action-detail__date
    display: table
    padding: .8rem 1rem
    margin-bottom: 12px
    background-color: $primary
    color: $white

.action-detail__category
    display: table
    padding: .8rem 1rem
    margin-bottom: 12px
    background-color: $secondary
    color: $dark
    border: 1px solid $dark

.action-detail__gallery
    position: relative
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 2rem
    margin-bottom: 2rem

    @media screen and (max-width: $md)
        grid-template-columns: repeat(3, 1fr)

    @media screen and (max-width: $xs)
        grid-template-columns: repeat(2, 1fr)
        gap: 1rem

    a
        position: relative
        display: block
        width: 100%

        &::after
            content: ''
            position: absolute
            inset: 0
            width: 100%
            height: 100%
            background-color: rgba($primary,.5)
            opacity: 0
            transition: opacity 250ms ease-in-out

        &::before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 4rem
            height: 4rem
            background-image: url('../img/icon/search.svg')
            background-size: contain
            opacity: 0
            z-index: 2
            transition: opacity 250ms ease-in-out

        &:hover
            &::after,
            &::before
                opacity: 1

    img
        display: block
        width: 100%
        object-fit: cover
        aspect-ratio: 1 / 1
