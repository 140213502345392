main

    & > section
        padding: 7rem 0

        @media screen and (max-width: $lg)
            padding: 5rem 0

        & + section
            padding: 0 0 7rem 0

            @media screen and (max-width: $lg)
                padding: 0 0 5rem 0
