// HEADINGS
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5
    font-weight: 700
    color: $dark
    font-family: $ff-secondary

h1,
.h1
    font-size: 6rem

    @media screen and (max-width: $md)
        font-size: 5rem

    @media screen and (max-width: $xs)
        font-size: 4.2rem

h2,
.h2
    font-size: 4.8rem

    @media screen and (max-width: $md)
        font-size: 4rem

    @media screen and (max-width: $xs)
        font-size: 3rem

h3,
.h3
    font-size: 3.6rem

    @media screen and (max-width: $md)
        font-size: 3rem

    @media screen and (max-width: $xs)
        font-size: 2.4rem

h4,
.h4
    font-size: 2.2rem

    @media screen and (max-width: $md)
        font-size: 2rem

    @media screen and (max-width: $xs)
        font-size: 1.8rem

h5,
.h5
    font-size: 2rem

    @media screen and (max-width: $md)
        font-size: 1.8rem

// POSITION
.text-center
    text-align: center

// COLORS
.color-primary
    color: $primary

.color-white
    color: $white

// SPACING
.mb-0
    margin-bottom: 0

.mb-1
    margin-bottom: 1.2rem

.mb-2
    margin-bottom: 2.4rem

.mb-3
    margin-bottom: 3.6rem

.mb-4
    margin-bottom: 4.8rem

.mt-0
    margin-top: 0

.mt-1
    margin-top: 1.2rem

.mt-2
    margin-top: 2.4rem

.mt-3
    margin-top: 3.6rem

.mt-4
    margin-top: 4.8rem

.pt-0
    padding-top: 0
