.footer
    padding-top: 6rem
    border-top: 5px solid $primary

    @media screen and (max-width: $xs)
        padding-top: 4rem

.footer__content
    display: grid
    grid-template-columns: 1fr 2fr
    gap: 5rem
    padding-bottom: 6rem

    @media screen and (max-width: $md)
        grid-template-columns: 1fr

    @media screen and (max-width: $xs)
        padding-bottom: 4rem


.footer__info
    display: flex
    flex-direction: column

    h2
        max-width: 25rem
        margin-bottom: 3rem

.footer__logo
    margin-bottom: 2rem

.footer__info__address,
.footer__info__phone,
.footer__info__email
    position: relative
    display: block
    font-size: 1.6rem
    text-decoration: none
    color: $dark
    padding-left: 3rem

    &::before
        content: ''
        position: absolute
        top: .4rem
        left: 0
        width: 2rem
        height: 2rem
        background-size: contain
        background-image: url('../img/icon/phone.svg')

.footer__info__address
    margin-bottom: 2rem

    &:before
        background-image: url('../img/icon/marker.svg')

.footer__info__phone
    margin-bottom: .5rem

    &:hover
        text-decoration: underline

    &:before
        background-image: url('../img/icon/phone.svg')

.footer__info__email
    margin-bottom: 3rem

    &:hover
        text-decoration: underline

    &:before
        background-image: url('../img/icon/email.svg')

.footer__info__social
    display: flex
    gap: 1rem

    a
        display: block
        text-decoration: none

.footer__nav
    display: flex
    flex-direction: column

.footer__menu
    position: relative
    display: grid
    grid-template-columns: 1fr 1fr
    padding-bottom: 8rem

    @media screen and (max-width: $xs)
        padding-bottom: 6rem

    &::before
        content: ''
        position: absolute
        bottom: 4rem
        left: 0
        width: 100%
        height: 1px
        background-color: $primary

        @media screen and (max-width: $xs)
            bottom: 3rem

.footer__links
    a
        display: block
        text-decoration: none
        color: $dark
        padding: .5rem 0

        &:hover
            text-decoration: underline

.footer__heading
    color: $primary
    font-size: 2rem
    margin-bottom: 1rem

.footer__partners
    display: flex
    justify-content: center
    gap: 2rem

    @media screen and (max-width: $sm)
        flex-direction: column
        align-items: center
        gap: 1rem

.footer__partner
    display: flex
    background-color: $white
    padding: 1rem 2rem
    width: 17rem

    img
        display: block
        width: 100%
        max-height: 6.3rem
        object-fit: contain

.footer__copyright
    text-align: center
    background-color: $primary
    color: $white
    padding: 2rem
