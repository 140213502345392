.catalog
    position: relative

.catalog__heading
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    gap: 2rem

    span
        display: block
        color: $primary
        font-size: 1.8rem
        font-weight: 700
        text-transform: uppercase

        @media screen and (max-width: $xs)
            font-size: 1.6rem

.catalog__list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    column-gap: 2.6rem
    row-gap: 5rem

    &.--banner
        margin-bottom: 3.6rem

        @media screen and (max-width: $lg)
            grid-template-columns: 1fr 1fr 1fr
            column-gap: 1rem

            a:nth-child(n+4)
                display: none

        @media screen and (max-width: $sm)
            grid-template-columns: 1fr 1fr

            a:nth-child(n+3)
                display: none

        .catalog__item
            text-align: left
            color: $dark
            background: $white

            .h5
                color: $dark
                font-weight: 600

    @media screen and (max-width: $lg)
        grid-template-columns: 1fr 1fr 1fr

    @media screen and (max-width: $md)
        grid-template-columns: 1fr 1fr

    @media screen and (max-width: $xs)
        grid-template-columns: 1fr
        row-gap: 2rem

.catalog__item
    position: relative
    display: block
    text-decoration: none !important
    color: $dark

    &:hover
        .catalog__item__image

            img
                transform: scale(1.05)

.catalog__item__tags
    display: flex
    gap: .5rem
    flex-wrap: wrap
    position: absolute
    top: 1rem
    left: 1rem
    z-index: 1

    span
        background-color: $primary
        color: $white
        border-radius: 3px
        font-size: 1.4rem
        padding: .4rem 1rem

.catalog__item__image
    height: 20rem
    overflow: hidden

    @media screen and (max-width: $sm)
        height: 15rem

    img
        display: block
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 250ms ease-in-out

.catalog__item__info
    display: flex
    flex-direction: column
    gap: .8rem
    padding: 1.6rem

    @media screen and (max-width: $xs)
        padding: 1.4rem 1rem

        h2
            font-size: 1.6rem

.catalog__item__date
    font-size: 1.4rem

.catalog__item__perex
    font-size: 1.4rem

    p
        font-size: 1.4rem

.catalog__filter
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 1rem
    margin: 5rem 0 4rem 0

    & .btn
        margin-left: auto

.catalog__filter__item

    label
        display: block
        border: 1px solid $primary
        border-radius: 1rem
        padding: 1rem 2rem
        background-color: transparent
        color: $dark
        font-size: 1.8rem
        line-height: 1
        cursor: pointer
        transition: all 250ms ease-in-out

    &:hover

        label
            color: $white
            border-color: $primary-hover
            background-color: $primary-hover

    input[type="checkbox"],
    input[type="radio"]
        display: none

        &:checked
            & + label
                color: $white
                border-color: $primary-hover
                background-color: $primary-hover

.catalog__pagination
    display: flex
    flex-direction: column
    align-items: center
    margin: 5rem 0

    a
        &:hover
            text-decoration: none

.catalog__pagination__num
    color: $dark
    margin-bottom: 2rem
    font-weight: 700
    font-size: fz(16px,18px)



