.text
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
        color: $dark
        font-weight: 700
        font-family: $ff-secondary

    p
        color: $dark
        font-size: 1.8rem
        line-height: 1.6

        &:not(:last-child)
            margin-bottom: 1rem

        a
            display: inline-block
            color: $dark
            text-decoration: underline

            &:hover
                text-decoration: none

    a
        display: block
        color: $dark
        text-decoration: none
        line-height: 1.3em
        font-size: 1.8rem

        &:hover
            text-decoration: underline

    ul,
    ol
        margin: 2rem 0

        li
            position: relative
            padding-left: 2rem

            &::before
                content: ''
                position: absolute
                top: .55em
                left: 0
                width: 5px
                height: 5px
                background-color: $primary
                border-radius: 50%

    ol
        list-style: none
        counter-reset: number

        li
            counter-increment: number

            &:before
                display: none

            &:after
                content: counter(number) ". "
                position: absolute
                top: 1px
                left: 0
                color: $primary

    table
        margin: 2rem 0
        width: 100%
        border-collapse: collapse

        thead

            tr
                background: $dark
                color: $white
                border-bottom: 1px solid #ccc

                th
                    text-align: left
                    padding: 1rem 2rem

        tbody

            tr
                border-bottom: 1px solid #ccc

                &:nth-child(even)
                    background-color: rgba($dark,.1)

                td
                    padding: 1rem 2rem

    &.--gallery
        position: relative
        display: grid
        grid-template-columns: repeat(4, 1fr)
        gap: 2rem
        margin: 3rem 0

        @media screen and (max-width: $md)
            grid-template-columns: repeat(3, 1fr)

        @media screen and (max-width: $xs)
            grid-template-columns: repeat(2, 1fr)
            gap: 1rem

        a
            position: relative
            display: block
            width: 100%

            &::after
                content: ''
                position: absolute
                inset: 0
                width: 100%
                height: 100%
                background-color: rgba($primary,.5)
                opacity: 0
                transition: opacity 250ms ease-in-out

            &::before
                content: ''
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
                width: 4rem
                height: 4rem
                background-image: url('../img/icon/search.svg')
                background-size: contain
                opacity: 0
                z-index: 2
                transition: opacity 250ms ease-in-out

            &:hover
                &::after,
                &::before
                    opacity: 1

        img
            display: block
            width: 100%
            object-fit: cover
            aspect-ratio: 1 / 1
