*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote {
  margin: 0;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
  font-family: "Inter", sans-serif;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.4;
  padding-top: 12rem;
  overflow-x: hidden;
  background-color: #EFE7E1;
}
@media screen and (max-width: 1199px) {
  body {
    padding-top: 9.5rem;
  }
}
@media screen and (max-width: 575px) {
  body {
    padding-top: 8.2rem;
  }
}
body.is-fixed {
  overflow: hidden;
}

a:focus {
  outline: none;
}
a:focus-visible {
  outline: 2px solid #333334;
}

button:focus {
  outline: none;
}
button:focus-visible {
  outline: 2px solid #333334;
}

ul {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 700;
  color: #333334;
  font-family: "Inter", sans-serif;
}

h1,
.h1 {
  font-size: 6rem;
}
@media screen and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 5rem;
  }
}
@media screen and (max-width: 575px) {
  h1,
  .h1 {
    font-size: 4.2rem;
  }
}

h2,
.h2 {
  font-size: 4.8rem;
}
@media screen and (max-width: 991px) {
  h2,
  .h2 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 575px) {
  h2,
  .h2 {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: 3.6rem;
}
@media screen and (max-width: 991px) {
  h3,
  .h3 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 575px) {
  h3,
  .h3 {
    font-size: 2.4rem;
  }
}

h4,
.h4 {
  font-size: 2.2rem;
}
@media screen and (max-width: 991px) {
  h4,
  .h4 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 575px) {
  h4,
  .h4 {
    font-size: 1.8rem;
  }
}

h5,
.h5 {
  font-size: 2rem;
}
@media screen and (max-width: 991px) {
  h5,
  .h5 {
    font-size: 1.8rem;
  }
}

.text-center {
  text-align: center;
}

.color-primary {
  color: #f1865e;
}

.color-white {
  color: #fff;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1.2rem;
}

.mb-2 {
  margin-bottom: 2.4rem;
}

.mb-3 {
  margin-bottom: 3.6rem;
}

.mb-4 {
  margin-bottom: 4.8rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1.2rem;
}

.mt-2 {
  margin-top: 2.4rem;
}

.mt-3 {
  margin-top: 3.6rem;
}

.mt-4 {
  margin-top: 4.8rem;
}

.pt-0 {
  padding-top: 0;
}

.container {
  max-width: 148rem;
  padding: 0 3rem;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .container {
    padding: 0 2rem;
  }
}

.footer {
  padding-top: 6rem;
  border-top: 5px solid #f1865e;
}
@media screen and (max-width: 575px) {
  .footer {
    padding-top: 4rem;
  }
}

.footer__content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5rem;
  padding-bottom: 6rem;
}
@media screen and (max-width: 991px) {
  .footer__content {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 575px) {
  .footer__content {
    padding-bottom: 4rem;
  }
}

.footer__info {
  display: flex;
  flex-direction: column;
}
.footer__info h2 {
  max-width: 25rem;
  margin-bottom: 3rem;
}

.footer__logo {
  margin-bottom: 2rem;
}

.footer__info__address,
.footer__info__phone,
.footer__info__email {
  position: relative;
  display: block;
  font-size: 1.6rem;
  text-decoration: none;
  color: #333334;
  padding-left: 3rem;
}
.footer__info__address::before,
.footer__info__phone::before,
.footer__info__email::before {
  content: "";
  position: absolute;
  top: 0.4rem;
  left: 0;
  width: 2rem;
  height: 2rem;
  background-size: contain;
  background-image: url("../img/icon/phone.svg");
}

.footer__info__address {
  margin-bottom: 2rem;
}
.footer__info__address:before {
  background-image: url("../img/icon/marker.svg");
}

.footer__info__phone {
  margin-bottom: 0.5rem;
}
.footer__info__phone:hover {
  text-decoration: underline;
}
.footer__info__phone:before {
  background-image: url("../img/icon/phone.svg");
}

.footer__info__email {
  margin-bottom: 3rem;
}
.footer__info__email:hover {
  text-decoration: underline;
}
.footer__info__email:before {
  background-image: url("../img/icon/email.svg");
}

.footer__info__social {
  display: flex;
  gap: 1rem;
}
.footer__info__social a {
  display: block;
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-direction: column;
}

.footer__menu {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 8rem;
}
@media screen and (max-width: 575px) {
  .footer__menu {
    padding-bottom: 6rem;
  }
}
.footer__menu::before {
  content: "";
  position: absolute;
  bottom: 4rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #f1865e;
}
@media screen and (max-width: 575px) {
  .footer__menu::before {
    bottom: 3rem;
  }
}

.footer__links a {
  display: block;
  text-decoration: none;
  color: #333334;
  padding: 0.5rem 0;
}
.footer__links a:hover {
  text-decoration: underline;
}

.footer__heading {
  color: #f1865e;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.footer__partners {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
@media screen and (max-width: 767px) {
  .footer__partners {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

.footer__partner {
  display: flex;
  background-color: #fff;
  padding: 1rem 2rem;
  width: 17rem;
}
.footer__partner img {
  display: block;
  width: 100%;
  max-height: 6.3rem;
  object-fit: contain;
}

.footer__copyright {
  text-align: center;
  background-color: #f1865e;
  color: #fff;
  padding: 2rem;
}

.nav {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .nav {
    flex-direction: column;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-15px);
    transition: all 250ms ease-in-out;
    overflow-y: auto;
    z-index: 999;
  }
  .nav ul {
    flex-direction: column;
    justify-content: center;
  }
  .nav.is-open {
    padding: 1rem 0 15rem 0;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.nav__info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100.5rem;
  margin-left: auto;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid rgba(51, 51, 52, 0.5);
}
@media screen and (max-width: 1199px) {
  .nav__info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: initial;
    max-width: 100%;
  }
}

.nav__social {
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 1199px) {
  .nav__social {
    margin-bottom: 1rem;
  }
}

.nav__links {
  display: flex;
  align-items: center;
}
.nav__links a {
  display: block;
  color: #f1865e;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.6rem 1rem;
}
.nav__links a:hover {
  text-decoration: underline;
}

.nav__list {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.nav__list.nest-depth--1 > .nav__item.has-submenu span,
.nav__list.nest-depth--1 > .nav__item.has-submenu ul,
.nav__list.nest-depth--2 > .nav__item.has-submenu span,
.nav__list.nest-depth--2 > .nav__item.has-submenu ul,
.nav__list.nest-depth--3 > .nav__item.has-submenu span,
.nav__list.nest-depth--3 > .nav__item.has-submenu ul {
  display: none;
}

.nav__item {
  display: flex;
}
@media screen and (max-width: 1199px) {
  .nav__item {
    display: block;
  }
}
.nav__item.has-submenu {
  position: relative;
}
.nav__item.has-submenu:last-child ul {
  left: unset;
  right: 0;
}
.nav__item.has-submenu a {
  padding-right: 3rem;
}
.nav__item.has-submenu a::after {
  display: none;
}
.nav__item.has-submenu ul {
  position: absolute;
  min-width: 26rem;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  left: 0;
  top: 100%;
  transform: translateY(-25px);
  transition: all 250ms;
  flex-direction: column;
}
.nav__item.has-submenu ul.nest-depth--1 {
  justify-content: unset;
}
.nav__item.has-submenu ul.nest-depth--2, .nav__item.has-submenu ul.nest-depth--3, .nav__item.has-submenu ul.nest-depth--4 {
  justify-content: unset;
  right: unset;
  opacity: 0 !important;
}
.nav__item.has-submenu ul.nest-depth--2.is-open, .nav__item.has-submenu ul.nest-depth--3.is-open, .nav__item.has-submenu ul.nest-depth--4.is-open {
  opacity: 1 !important;
}
.nav__item.has-submenu ul li {
  flex-grow: 1;
}
.nav__item.has-submenu ul li a {
  position: relative;
  display: block;
  width: 100%;
  color: #333334;
  padding: 0.8rem 2rem;
  text-decoration: none;
  overflow: hidden;
  font-size: 1.8rem;
  transition: color 250ms ease-in-out;
  text-align: left;
  white-space: initial;
}
@media screen and (max-width: 1199px) {
  .nav__item.has-submenu ul li a {
    font-size: 1.6rem;
    padding: 0.8rem 2rem 0.8rem 5rem;
  }
}
.nav__item.has-submenu ul li a:hover {
  color: #f1865e;
}
@media screen and (min-width: 1200px) {
  .nav__item.has-submenu span:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-image: url("../img/icon/v-down.svg");
    background-position: center;
    transition: transform 250ms;
  }
}
@media screen and (max-width: 1199px) {
  .nav__item.has-submenu span {
    display: inline-block;
    position: absolute;
    top: 1rem;
    right: 2rem;
    width: 5.5rem;
    height: 4.5rem;
    cursor: pointer;
    background-color: #f1865e;
  }
  .nav__item.has-submenu span:before {
    content: "";
    position: absolute;
    top: 12px;
    right: 17px;
    transform: scale(0.9);
    width: 2.2rem;
    height: 2.2rem;
    background-image: url("../img/icon/v-down.svg");
    transition: transform 250ms ease-in-out;
  }
  .nav__item.has-submenu span:hover {
    border-color: #f1865e;
  }
}
@media screen and (max-width: 575px) {
  .nav__item.has-submenu span {
    top: 1rem;
  }
}
@media screen and (min-width: 1200px) {
  .nav__item.has-submenu:hover ul, .nav__item.has-submenu.is-open ul {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (max-width: 1199px) {
  .nav__item.has-submenu.is-open ul {
    position: relative;
    background-color: #333334;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
  .nav__item.has-submenu.is-open span {
    border-color: #333334;
  }
}

.nav__link {
  position: relative;
  display: table;
  color: #333334;
  padding: 1.4rem 2rem;
  text-decoration: none;
  overflow: hidden;
  font-size: 1.6rem;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-width: 1199px) {
  .nav__link {
    position: relative;
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
}

.nav__item.--active a, .nav__item.--child-active a {
  color: #f1865e;
}
@media screen and (max-width: 1199px) {
  .nav__item .nav__list.is-open {
    position: relative;
    background-color: #fff;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    left: unset;
    top: unset;
  }
  .nav__item .nav__list.is-open span {
    border-color: #333334;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1920px;
  z-index: 99;
  padding: 1rem 0;
  background-color: #fff;
  transition: padding 250ms ease-in-out;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

@media screen and (max-width: 1199px) {
  .header__logo {
    max-width: 11rem;
  }
}
@media screen and (max-width: 575px) {
  .header__logo {
    max-width: 9rem;
  }
}

main > section {
  padding: 7rem 0;
}
@media screen and (max-width: 1199px) {
  main > section {
    padding: 5rem 0;
  }
}
main > section + section {
  padding: 0 0 7rem 0;
}
@media screen and (max-width: 1199px) {
  main > section + section {
    padding: 0 0 5rem 0;
  }
}

.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  color: #333334;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.text p {
  color: #333334;
  font-size: 1.8rem;
  line-height: 1.6;
}
.text p:not(:last-child) {
  margin-bottom: 1rem;
}
.text p a {
  display: inline-block;
  color: #333334;
  text-decoration: underline;
}
.text p a:hover {
  text-decoration: none;
}
.text a {
  display: block;
  color: #333334;
  text-decoration: none;
  line-height: 1.3em;
  font-size: 1.8rem;
}
.text a:hover {
  text-decoration: underline;
}
.text ul,
.text ol {
  margin: 2rem 0;
}
.text ul li,
.text ol li {
  position: relative;
  padding-left: 2rem;
}
.text ul li::before,
.text ol li::before {
  content: "";
  position: absolute;
  top: 0.55em;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #f1865e;
  border-radius: 50%;
}
.text ol {
  list-style: none;
  counter-reset: number;
}
.text ol li {
  counter-increment: number;
}
.text ol li:before {
  display: none;
}
.text ol li:after {
  content: counter(number) ". ";
  position: absolute;
  top: 1px;
  left: 0;
  color: #f1865e;
}
.text table {
  margin: 2rem 0;
  width: 100%;
  border-collapse: collapse;
}
.text table thead tr {
  background: #333334;
  color: #fff;
  border-bottom: 1px solid #ccc;
}
.text table thead tr th {
  text-align: left;
  padding: 1rem 2rem;
}
.text table tbody tr {
  border-bottom: 1px solid #ccc;
}
.text table tbody tr:nth-child(even) {
  background-color: rgba(51, 51, 52, 0.1);
}
.text table tbody tr td {
  padding: 1rem 2rem;
}
.text.--gallery {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin: 3rem 0;
}
@media screen and (max-width: 991px) {
  .text.--gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 575px) {
  .text.--gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.text.--gallery a {
  position: relative;
  display: block;
  width: 100%;
}
.text.--gallery a::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 134, 94, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
.text.--gallery a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  background-image: url("../img/icon/search.svg");
  background-size: contain;
  opacity: 0;
  z-index: 2;
  transition: opacity 250ms ease-in-out;
}
.text.--gallery a:hover::after, .text.--gallery a:hover::before {
  opacity: 1;
}
.text.--gallery img {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

a.fill__link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.btn {
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;
  padding: 1.6rem 3.6rem;
  color: #fff;
  background-color: #f1865e;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 250ms;
  font-size: 1.8rem;
}
@media screen and (max-width: 767px) {
  .btn {
    font-size: 1.6rem;
    padding: 1.2rem 2.4rem;
  }
}
.btn:hover {
  color: #fff;
  background-color: #e0754e;
}
.btn.--center {
  margin: 0 auto;
}

.hamburger {
  position: relative;
  display: none;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  z-index: 9999;
}
@media screen and (max-width: 1199px) {
  .hamburger {
    display: block;
  }
}
.hamburger input {
  display: none;
}
.hamburger:before, .hamburger:after,
.hamburger span {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3rem;
  height: 0.4rem;
  background-color: #333334;
  border-radius: 3rem;
  opacity: 1;
  transition: transform 250ms;
}
.hamburger:before {
  top: 2.4rem;
}
.hamburger:after {
  top: 0.6rem;
}
.hamburger span {
  top: 50%;
  transition: opacity 250ms;
}
.hamburger.is-active:before {
  top: 1.2rem;
  transform: translateX(-50%) rotate(-45deg);
}
.hamburger.is-active:after {
  top: 1.2rem;
  transform: translateX(-50%) rotate(45deg);
}
.hamburger.is-active span {
  opacity: 0;
}

form,
.form {
  width: 100%;
  margin: 0 auto;
}
form input[type=text],
form input[type=email],
form input[type=number],
form input[type=password],
form select,
form textarea,
.form input[type=text],
.form input[type=email],
.form input[type=number],
.form input[type=password],
.form select,
.form textarea {
  width: 100%;
  height: 5rem;
  padding: 1rem 2rem;
  line-height: 1.3rem;
  border: 2px solid rgba(51, 51, 52, 0.25);
  border-radius: 1rem;
  outline: none;
  background-color: #fff;
  color: #333334;
  font-size: 1.6rem;
  font-size: fz(16px, 18px);
}
form input[type=text]::placeholder,
form input[type=email]::placeholder,
form input[type=number]::placeholder,
form input[type=password]::placeholder,
form select::placeholder,
form textarea::placeholder,
.form input[type=text]::placeholder,
.form input[type=email]::placeholder,
.form input[type=number]::placeholder,
.form input[type=password]::placeholder,
.form select::placeholder,
.form textarea::placeholder {
  color: #B6AEB1;
  font-size: 14px;
}
form input[type=text]:active, form input[type=text]:focus, form input[type=text]:focus-visible,
form input[type=email]:active,
form input[type=email]:focus,
form input[type=email]:focus-visible,
form input[type=number]:active,
form input[type=number]:focus,
form input[type=number]:focus-visible,
form input[type=password]:active,
form input[type=password]:focus,
form input[type=password]:focus-visible,
form select:active,
form select:focus,
form select:focus-visible,
form textarea:active,
form textarea:focus,
form textarea:focus-visible,
.form input[type=text]:active,
.form input[type=text]:focus,
.form input[type=text]:focus-visible,
.form input[type=email]:active,
.form input[type=email]:focus,
.form input[type=email]:focus-visible,
.form input[type=number]:active,
.form input[type=number]:focus,
.form input[type=number]:focus-visible,
.form input[type=password]:active,
.form input[type=password]:focus,
.form input[type=password]:focus-visible,
.form select:active,
.form select:focus,
.form select:focus-visible,
.form textarea:active,
.form textarea:focus,
.form textarea:focus-visible {
  border-color: #333334;
}
form textarea,
.form textarea {
  padding: 2rem;
  min-height: 12rem;
  line-height: 1.3em;
}

.form__item {
  display: grid;
  column-gap: 2rem;
  row-gap: 0;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 100%;
}

.form-group {
  position: relative;
  margin-bottom: 3rem;
}
.form-group.mb-sm {
  margin-bottom: 0.5rem;
}
.form-group.has-error input {
  border-color: #d92637;
}
.form-group.has-error input::placeholder {
  color: #d92637;
}
.form-group span {
  display: block;
  color: #f1865e;
  background-color: transparent;
  margin-bottom: 1rem;
}
.form-group .validation {
  position: absolute;
  top: calc(100% - 1px);
  font-size: 14px;
  color: #d92637;
  letter-spacing: 1px;
  margin: 0.5rem 0 0 0.5rem;
}

.form__intro {
  display: block;
  color: #f1865e;
  font-weight: 700;
}

.form__label.--checkbox {
  cursor: pointer;
}
.form__label.--checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
.form__label.--checkbox input[type=checkbox] + span {
  position: relative;
  display: inline-block;
  color: #333334;
  padding-left: 3rem;
  margin: 0;
}
.form__label.--checkbox input[type=checkbox] + span:after, .form__label.--checkbox input[type=checkbox] + span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.3em;
  height: 1.3em;
  border-radius: 4px;
  transition: all 250ms ease-in-out;
}
.form__label.--checkbox input[type=checkbox] + span:after {
  background-color: transparent;
  border: 1px solid #f1865e;
  opacity: 1;
}
.form__label.--checkbox input[type=checkbox] + span::before {
  background-position: center;
  background-size: contain;
}
.form__label.--checkbox input[type=checkbox] + span:hover::before {
  background-image: url(../img/icon/check-form.svg);
}
.form__label.--checkbox input[type=checkbox]:checked + span:after {
  background-color: rgba(241, 134, 94, 0.7);
}
.form__label.--checkbox input[type=checkbox]:checked + span::before {
  background-image: url(../img/icon/check-form.svg);
  z-index: 1;
}
.form__label.--checkbox a {
  color: #333334;
  display: inline-block;
}
.form__label.--checkbox a:hover {
  text-decoration: none;
}

#form__inputs {
  display: flex;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
}

a[href$=".pdf"]::before {
  background-image: url("../img/files/pdf.svg");
}

a[href$=".doc"]::before,
a[href$=".docx"]::before {
  background-image: url("../img/files/word.svg");
}

a[href$=".xlsx"]::before,
a[href$=".xls"]::before {
  background-image: url("../img/files/excel.svg");
}

a[href$=".jpg"]::before,
a[href$=".jpeg"]::before,
a[href$=".png"]::before,
a[href$=".svg"]::before {
  background-image: url("../img/files/jpg.svg");
}

.files__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}

.files__item {
  width: calc(50% - 16px);
  border: 1px solid #333334;
  padding: 16px;
  background-color: #fff;
  display: flex;
  align-items: center;
  transition: all 250ms ease-in-out;
  position: relative;
  color: #333334;
}
.files__item a,
.files__item div {
  color: #333334;
  text-decoration: none;
}
.files__item:hover {
  transform: translate(0, -5px);
  box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 991px) {
  .files__item {
    width: 100%;
  }
}

.files__item-file-type {
  width: 100%;
  position: relative;
  padding: 0 0 0 50px;
  font-weight: 700;
  word-break: break-word;
}
.files__item-file-type::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(0, -50%);
}

.breadcrumb__list {
  display: flex;
  gap: 12px;
}

.breadcrumb__item {
  display: flex;
  gap: 12px;
  color: #333334;
}
.breadcrumb__item a {
  color: #333334;
}
.breadcrumb__item.--last {
  color: #333334;
  font-weight: 700;
}

.breadcrumb__list {
  margin-bottom: 24px;
}

.pagination__numbers {
  text-align: center;
  margin-bottom: 12px;
  margin-top: 24px;
}

.pagination {
  margin-bottom: 24px;
}

.page-detail__title {
  margin-bottom: 16px;
}

.page__bg-img img {
  width: 100%;
  height: 30rem;
  object-fit: cover;
  display: block;
}
@media screen and (max-width: 575px) {
  .page__bg-img img {
    height: 20rem;
  }
}

.nav__submenu {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.nav__submenu > .nav__sub-item {
  border: 1px solid #000;
  padding: 16px;
}
.nav__submenu > .nav__sub-item.--child-active > .nav__sub-link,
.nav__submenu > .nav__sub-item .--active > .nav__sub-link {
  font-weight: 700;
}
.nav__submenu > .nav__sub-item > .nav__sub-link {
  display: table;
}

.nav__sub-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.nav__sub-list.nest-depth--1, .nav__sub-list.nest-depth--2 {
  padding-left: 8px;
  display: none;
}

.nav__sub-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.nav__sub-item.--child-active > .nav__sub-list,
.nav__sub-item .--active > .nav__sub-list {
  display: block;
}

.nav__sub-link {
  transition: all 250ms ease-in-out;
  color: #333334;
}
.nav__sub-link:hover {
  padding-left: 8px;
}

.action {
  position: relative;
}

.action__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.6rem;
  row-gap: 5rem;
}
.action__list.--banner {
  margin-bottom: 3.6rem;
}
@media screen and (max-width: 1199px) {
  .action__list.--banner {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  .action__list.--banner a:nth-child(n+4) {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .action__list.--banner {
    grid-template-columns: 1fr 1fr;
  }
  .action__list.--banner a:nth-child(n+3) {
    display: none;
  }
}
.action__list.--banner .action__item {
  text-align: left;
  color: #333334;
  background: #fff;
}
.action__list.--banner .action__item .h5 {
  color: #333334;
  font-weight: 600;
}
@media screen and (max-width: 1199px) {
  .action__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 991px) {
  .action__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 575px) {
  .action__list {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

.action__item {
  position: relative;
  display: block;
  text-decoration: none !important;
  color: #333334;
}
.action__item:hover .action__item__image img {
  transform: scale(1.05);
}

.action__item__tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
}
.action__item__tags span {
  background-color: #f1865e;
  color: #fff;
  border-radius: 3px;
  font-size: 1.4rem;
  padding: 0.4rem 1rem;
}

.action__item__image {
  height: 20rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .action__item__image {
    height: 15rem;
  }
}
.action__item__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 250ms ease-in-out;
}

.action__item__info {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.6rem;
}
@media screen and (max-width: 575px) {
  .action__item__info {
    padding: 1.4rem 1rem;
  }
  .action__item__info h2 {
    font-size: 1.6rem;
  }
}

.action__item__date {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.action__item__perex {
  font-size: 1.4rem;
}

.action__filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 5rem 0 4rem 0;
}
.action__filter .btn {
  margin-left: auto;
}

.action__filter__item label {
  display: block;
  border: 1px solid #f1865e;
  border-radius: 1rem;
  padding: 1rem 2rem;
  background-color: transparent;
  color: #333334;
  font-size: 1.8rem;
  line-height: 1;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}
.action__filter__item:hover label {
  color: #fff;
  border-color: #e0754e;
  background-color: #e0754e;
}
.action__filter__item input[type=radio] {
  display: none;
}
.action__filter__item input[type=radio]:checked + label {
  color: #fff;
  border-color: #e0754e;
  background-color: #e0754e;
}

.action__pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
}
.action__pagination a:hover {
  text-decoration: none;
}

.action__pagination__num {
  color: #333334;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: fz(16px, 18px);
}

.catalog {
  position: relative;
}

.catalog__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
}
.catalog__heading span {
  display: block;
  color: #f1865e;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (max-width: 575px) {
  .catalog__heading span {
    font-size: 1.6rem;
  }
}

.catalog__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.6rem;
  row-gap: 5rem;
}
.catalog__list.--banner {
  margin-bottom: 3.6rem;
}
@media screen and (max-width: 1199px) {
  .catalog__list.--banner {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
  .catalog__list.--banner a:nth-child(n+4) {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .catalog__list.--banner {
    grid-template-columns: 1fr 1fr;
  }
  .catalog__list.--banner a:nth-child(n+3) {
    display: none;
  }
}
.catalog__list.--banner .catalog__item {
  text-align: left;
  color: #333334;
  background: #fff;
}
.catalog__list.--banner .catalog__item .h5 {
  color: #333334;
  font-weight: 600;
}
@media screen and (max-width: 1199px) {
  .catalog__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 991px) {
  .catalog__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 575px) {
  .catalog__list {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

.catalog__item {
  position: relative;
  display: block;
  text-decoration: none !important;
  color: #333334;
}
.catalog__item:hover .catalog__item__image img {
  transform: scale(1.05);
}

.catalog__item__tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
}
.catalog__item__tags span {
  background-color: #f1865e;
  color: #fff;
  border-radius: 3px;
  font-size: 1.4rem;
  padding: 0.4rem 1rem;
}

.catalog__item__image {
  height: 20rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .catalog__item__image {
    height: 15rem;
  }
}
.catalog__item__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 250ms ease-in-out;
}

.catalog__item__info {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.6rem;
}
@media screen and (max-width: 575px) {
  .catalog__item__info {
    padding: 1.4rem 1rem;
  }
  .catalog__item__info h2 {
    font-size: 1.6rem;
  }
}

.catalog__item__date {
  font-size: 1.4rem;
}

.catalog__item__perex {
  font-size: 1.4rem;
}
.catalog__item__perex p {
  font-size: 1.4rem;
}

.catalog__filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 5rem 0 4rem 0;
}
.catalog__filter .btn {
  margin-left: auto;
}

.catalog__filter__item label {
  display: block;
  border: 1px solid #f1865e;
  border-radius: 1rem;
  padding: 1rem 2rem;
  background-color: transparent;
  color: #333334;
  font-size: 1.8rem;
  line-height: 1;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}
.catalog__filter__item:hover label {
  color: #fff;
  border-color: #e0754e;
  background-color: #e0754e;
}
.catalog__filter__item input[type=checkbox],
.catalog__filter__item input[type=radio] {
  display: none;
}
.catalog__filter__item input[type=checkbox]:checked + label,
.catalog__filter__item input[type=radio]:checked + label {
  color: #fff;
  border-color: #e0754e;
  background-color: #e0754e;
}

.catalog__pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
}
.catalog__pagination a:hover {
  text-decoration: none;
}

.catalog__pagination__num {
  color: #333334;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: fz(16px, 18px);
}

.action-detail {
  position: relative;
}

.action-detail__content {
  display: flex;
  flex-direction: column;
}

.action-detail__tags {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.action-detail__date {
  display: table;
  padding: 0.8rem 1rem;
  margin-bottom: 12px;
  background-color: #f1865e;
  color: #fff;
}

.action-detail__category {
  display: table;
  padding: 0.8rem 1rem;
  margin-bottom: 12px;
  background-color: #EFE7E1;
  color: #333334;
  border: 1px solid #333334;
}

.action-detail__gallery {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 991px) {
  .action-detail__gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 575px) {
  .action-detail__gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.action-detail__gallery a {
  position: relative;
  display: block;
  width: 100%;
}
.action-detail__gallery a::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 134, 94, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
.action-detail__gallery a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  background-image: url("../img/icon/search.svg");
  background-size: contain;
  opacity: 0;
  z-index: 2;
  transition: opacity 250ms ease-in-out;
}
.action-detail__gallery a:hover::after, .action-detail__gallery a:hover::before {
  opacity: 1;
}
.action-detail__gallery img {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.catalog-detail {
  position: relative;
}

.catalog-detail__content {
  display: flex;
  flex-direction: column;
}

.catalog-detail__tags {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.catalog-detail__date {
  display: table;
  padding: 0.8rem 1rem;
  margin-bottom: 12px;
  background-color: #f1865e;
  color: #fff;
}

.catalog-detail__category {
  display: table;
  padding: 0.8rem 1rem;
  margin-bottom: 12px;
  background-color: #EFE7E1;
  color: #333334;
  border: 1px solid #333334;
}

.catalog-detail__gallery {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 991px) {
  .catalog-detail__gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 575px) {
  .catalog-detail__gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.catalog-detail__gallery a {
  position: relative;
  display: block;
  width: 100%;
}
.catalog-detail__gallery a::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 134, 94, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
.catalog-detail__gallery a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  background-image: url("../img/icon/search.svg");
  background-size: contain;
  opacity: 0;
  z-index: 2;
  transition: opacity 250ms ease-in-out;
}
.catalog-detail__gallery a:hover::after, .catalog-detail__gallery a:hover::before {
  opacity: 1;
}
.catalog-detail__gallery img {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.branch {
  position: relative;
}

.branch__text {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: center;
  gap: 5rem;
  margin-bottom: 10rem;
}
@media screen and (max-width: 991px) {
  .branch__text {
    grid-template-columns: 1fr;
    gap: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .branch__text {
    margin-bottom: 6rem;
  }
}
.branch__text::before {
  content: "";
  position: absolute;
  bottom: -5rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #333334;
}
@media screen and (max-width: 767px) {
  .branch__text::before {
    bottom: -3.5rem;
  }
}

.branch__list {
  display: flex;
  flex-direction: column;
  gap: 10rem;
}
@media screen and (max-width: 767px) {
  .branch__list {
    gap: 6rem;
  }
}
.branch__list .branch__item:last-child::before {
  display: none;
}

.branch__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 6.6rem;
}
@media screen and (max-width: 991px) {
  .branch__item {
    gap: 4.6rem;
  }
}
@media screen and (max-width: 767px) {
  .branch__item {
    grid-template-columns: 1fr;
    gap: 2.6rem;
  }
}
.branch__item::before {
  content: "";
  position: absolute;
  bottom: -5rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #333334;
}
@media screen and (max-width: 767px) {
  .branch__item::before {
    bottom: -3.5rem;
  }
}
.branch__item:nth-child(even) .branch__info {
  order: 2;
}
@media screen and (max-width: 767px) {
  .branch__item:nth-child(even) .branch__info {
    order: initial;
  }
}

.branch__info {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}
@media screen and (max-width: 767px) {
  .branch__info {
    gap: 2.4rem;
  }
}

.branch__image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.2rem;
}
@media screen and (max-width: 991px) {
  .branch__image {
    grid-template-columns: 1fr;
  }
  .branch__image picture:last-child {
    display: none;
  }
  .branch__image img {
    aspect-ratio: 1/1;
  }
}
@media screen and (max-width: 767px) {
  .branch__image img {
    aspect-ratio: 2/1;
  }
}
.branch__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1/1.25;
}

.branch-detail {
  position: relative;
}

.branch-detail__info {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 5rem;
  padding-bottom: 16rem;
}
@media screen and (max-width: 991px) {
  .branch-detail__info {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .branch-detail__info {
    padding-bottom: 10rem;
  }
}
.branch-detail__info::before {
  content: "";
  position: absolute;
  bottom: 8rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 52, 0.5);
}
@media screen and (max-width: 767px) {
  .branch-detail__info::before {
    bottom: 5rem;
  }
}

.branch-detail__sub {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 5rem;
}

.branch-detail__info__left span {
  display: block;
  color: #f1865e;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media screen and (max-width: 575px) {
  .branch-detail__info__left span {
    font-size: 1.6rem;
  }
}

.branch-detail__info__adress {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
@media screen and (max-width: 575px) {
  .branch-detail__info__adress {
    grid-template-columns: 1fr;
  }
}

.branch-detail__info__adress__item {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.branch-detail__info__adress__item p {
  position: relative;
  padding-left: 2.5rem;
  max-width: 19rem;
}
.branch-detail__info__adress__item p::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 2rem;
  height: 2rem;
  background-image: url("../img/icon/marker.svg");
}

.branch-detail__info__right {
  font-size: 1.6rem;
  line-height: 1.6;
}
.branch-detail__info__right a {
  color: #333334;
}

.branch-detail__text {
  margin-bottom: 5rem;
}

.branch-detail__gallery__images {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding-bottom: 16rem;
}
@media screen and (max-width: 991px) {
  .branch-detail__gallery__images {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .branch-detail__gallery__images {
    padding-bottom: 10rem;
  }
}
@media screen and (max-width: 575px) {
  .branch-detail__gallery__images {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.branch-detail__gallery__images::before {
  content: "";
  position: absolute;
  bottom: 8rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 52, 0.5);
}
@media screen and (max-width: 767px) {
  .branch-detail__gallery__images::before {
    bottom: 5rem;
  }
}
.branch-detail__gallery__images a {
  position: relative;
  display: block;
  width: 100%;
}
.branch-detail__gallery__images a::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 134, 94, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
.branch-detail__gallery__images a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  background-image: url("../img/icon/search.svg");
  background-size: contain;
  opacity: 0;
  z-index: 2;
  transition: opacity 250ms ease-in-out;
}
.branch-detail__gallery__images a:hover::after, .branch-detail__gallery__images a:hover::before {
  opacity: 1;
}
.branch-detail__gallery__images img {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.branch-detail__services {
  position: relative;
  padding-bottom: 16rem;
}
@media screen and (max-width: 767px) {
  .branch-detail__services {
    padding-bottom: 10rem;
  }
}
.branch-detail__services::before {
  content: "";
  position: absolute;
  bottom: 8rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 52, 0.5);
}
@media screen and (max-width: 767px) {
  .branch-detail__services::before {
    bottom: 5rem;
  }
}

.branch-detail__services__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
@media screen and (max-width: 991px) {
  .branch-detail__services__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .branch-detail__services__list {
    grid-template-columns: 1fr;
  }
}
.branch-detail__services__list li {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
  padding: 1.8rem;
  background-color: #fff;
}

.branch-detail__pages {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}
@media screen and (max-width: 1199px) {
  .branch-detail__pages {
    gap: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .branch-detail__pages {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.branch-detail__pages__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  text-decoration: none;
  background-color: #fff;
  padding: 2.4rem 3rem;
  border: 2px solid #fff;
  transition: border-color 250ms ease-in-out;
}
.branch-detail__pages__item:hover {
  border-color: #f1865e;
}
.branch-detail__pages__item img {
  width: 100%;
  max-width: 5.7rem;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .branch-detail__pages__item {
    padding: 2rem 1rem;
  }
  .branch-detail__pages__item img {
    max-width: 4rem;
  }
}

.collection {
  position: relative;
}

.collection__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}
@media screen and (max-width: 1199px) {
  .collection__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .collection__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.collection__item {
  display: block;
  text-decoration: none;
  color: #333334;
  transition: background-color 250ms ease-in-out;
}
.collection__item:hover {
  background-color: #f1865e;
  text-decoration: none !important;
}
.collection__item:hover .collection__text h2 {
  color: #fff;
}

.collection__text h2 {
  padding: 1.5rem;
  transition: color 250ms ease-in-out;
}

.collection__image img {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 2/1;
}
@media screen and (max-width: 767px) {
  .collection__image img {
    aspect-ratio: 1.5/1;
  }
}

.collection-main {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 2.6rem;
  padding: 7rem 0;
}
@media screen and (max-width: 1199px) {
  .collection-main {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1199px) {
  .collection-main {
    gap: 0;
  }
}
@media screen and (max-width: 575px) {
  .collection-main {
    padding: 5rem 0;
  }
}

.collection-main__image {
  height: 100%;
}
@media screen and (max-width: 1199px) {
  .collection-main__image {
    aspect-ratio: 2.5/1;
  }
}
@media screen and (max-width: 575px) {
  .collection-main__image {
    aspect-ratio: 1.5/1;
  }
}
.collection-main__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection-main__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: rgba(51, 51, 52, 0.1);
  padding: 5rem 12rem;
}
@media screen and (max-width: 1399px) {
  .collection-main__info {
    padding: 5rem 6rem;
  }
}
@media screen and (max-width: 991px) {
  .collection-main__info {
    padding: 5rem 3rem;
  }
}
@media screen and (max-width: 575px) {
  .collection-main__info {
    padding: 4rem 2rem;
  }
}
.collection-main__info span {
  display: block;
  color: #f1865e;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.4rem;
}
@media screen and (max-width: 575px) {
  .collection-main__info span {
    font-size: 1.6rem;
  }
}

.collection-main__info__text {
  max-width: 55rem;
  margin-bottom: 2rem;
}

.collection-main__wrapper {
  display: flex;
  gap: 16px;
}

.ss-result {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #333334;
}
.ss-result h4 {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  font-weight: 700;
}
.ss-result h4 a {
  color: #333334;
  text-decoration: none;
}
.ss-result h4 a:hover {
  text-decoration: underline;
}
.ss-result mark {
  font-weight: 600;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 6px;
}
.ss-result p {
  margin-bottom: 8px;
}
.ss-result__badge {
  display: inline-block;
  font-size: 11px;
  padding: 6px 11px;
  background-color: #f1865e;
  color: #fff;
  margin-left: 10px;
  border-radius: 8px;
  vertical-align: top;
}
.ss-result__link {
  font-weight: 600;
  position: relative;
  color: #f1865e;
  text-decoration: none;
}
.ss-result__link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .ss-result__title {
    display: flex;
    justify-content: space-between;
  }
}

.search-modal {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1080;
  background: #EFE7E1;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 250ms ease-in-out;
}
.search-modal.shown {
  display: flex;
  animation: toFullHeight 300ms ease-in-out forwards;
}
.search-modal input {
  background-color: #fff;
  font-size: 3.8rem;
  font-weight: 700;
  padding: 30px 40px;
  border: none;
  border-radius: 60px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .search-modal input {
    display: table;
    width: 100%;
    max-width: 100%;
    font-size: 2.4rem;
    padding: 20px 25px;
  }
}
@media (max-width: 575px) {
  .search-modal input {
    font-size: 1.8rem;
  }
}

.close-modal.close-modal-cross {
  position: absolute;
  width: 80px;
  height: 80px;
  display: block;
  border: 3px solid #f1865e;
  top: 30px;
  right: 30px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .close-modal.close-modal-cross {
    width: 50px;
    height: 50px;
  }
}
.close-modal.close-modal-cross:before, .close-modal.close-modal-cross:after {
  content: "";
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  height: 3px;
  display: block;
  background-color: #f1865e;
  transform: translate(-50%, -50%) rotate(45deg);
}
.close-modal.close-modal-cross:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ss-search-form__results.ss-search-form__results--visible {
  padding: 16px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .ss-search-form__results.ss-search-form__results--visible {
    padding: 16px 16px 8px;
  }
}

.ss-autocomplete-result {
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: none;
}
.ss-autocomplete-result__badge {
  display: inline-block;
  background-color: #f1865e;
  font-size: 1.4rem;
  letter-spacing: 0.75px;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
  margin-left: 10px;
}

.ss-autocomplete-result__title {
  padding: 0.5rem 0;
}
.ss-autocomplete-result__title a {
  color: #333334;
  text-decoration: none;
}

.ss-autocomplete-result__content {
  padding-bottom: 8px;
  margin-top: 8px;
}

.ss-show-all-results {
  padding: 8px 0;
}
.ss-show-all-results a {
  color: #f1865e;
}

.ss-result p {
  margin: 1rem 0;
}

@keyframes toFullHeight {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.newsletter {
  display: flex;
  align-items: center;
  background-color: #f1865e;
  padding: 60px 40px;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .newsletter {
    padding: 30px 20px;
  }
}

.newsletter__content {
  width: calc(50% - 40px);
  margin-right: 40px;
  color: #fff;
}
@media (max-width: 767px) {
  .newsletter__content {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.newsletter__title {
  color: #fff;
}

.newsletter__form {
  width: 50%;
}
@media (max-width: 767px) {
  .newsletter__form {
    width: 100%;
  }
}

.newsletter__form-wrapper {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  margin-bottom: 12px;
  align-items: center;
}
.newsletter__form-wrapper .btn {
  border: 1px solid #f1865e !important;
}
.newsletter__form-wrapper .btn:hover {
  border: 1px solid #f1865e;
}
.newsletter__form-wrapper input[type=email] {
  border: none;
  width: 500px;
  max-width: 100%;
  border: unset;
  padding-left: 20px;
}
.newsletter__form-wrapper .validation {
  position: absolute;
  top: calc(100% + 10px);
  color: red;
}
.newsletter__form-wrapper .btn-std {
  margin: 0;
  padding: 24px 45px;
  border: 2px solid #fff;
}
.newsletter__form-wrapper .newsletter_success {
  padding: 20px 10px;
  background-color: #28B231;
  text-align: center;
  color: #fff;
  width: 100%;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .newsletter__form-wrapper {
    padding: 0 0.5rem;
    align-items: center;
  }
  .newsletter__form-wrapper input[type=email] {
    width: 190px;
    flex-grow: 1;
    padding: 20px 12px;
  }
}

.ecomail__gdpr span {
  color: #fff;
  line-height: 1.4;
  text-align: center;
  display: block;
}

/**
 * Lightcase - jQuery Plugin
 * The smart and flexible Lightbox Plugin.
 *
 * @author		Cornel Boppart <cornel@bopp-art.com>
 * @copyright	Author
 *
 * @version		2.5.0 (11/03/2018)
 */
/**
 * Lightcase settings
 *
 * Note: Override default settings for your project without touching this source code by simply
 * defining those variables within a SASS map called '$lightcase-custom'.
 *
 * // Example usage
 * $lightcase-custom: (
 *   'breakpoint': 768px
 * );
 */
@font-face {
  font-family: "lightcase";
  src: url("../../lightcase/lightcase.eot?55356177");
  src: url("../../lightcase/lightcase.eot?55356177#iefix") format("embedded-opentype"), url("../../lightcase/lightcase.woff?55356177") format("woff"), url("../../lightcase/lightcase.ttf?55356177") format("truetype"), url("../../lightcase/lightcase.svg?55356177#lightcase") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*=lightcase-icon-]:before {
  font-family: "lightcase", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* Codes */
.lightcase-icon-play:before {
  content: "\e800";
}

.lightcase-icon-pause:before {
  content: "\e801";
}

.lightcase-icon-close:before {
  content: "\e802";
}

.lightcase-icon-prev:before {
  content: "\e803";
}

.lightcase-icon-next:before {
  content: "\e804";
}

.lightcase-icon-spin:before {
  content: "\e805";
}

/**
 * Mixin providing icon defaults to be used on top of font-lightcase.
 *
 * Example usage:
 * @include icon(#e9e9e9)
 */
/**
 * Mixin providing icon defaults including a hover status to be used
 * on top of font-lightcase.
 *
 * Example usage:
 * @include icon-hover(#e9e9e9, #fff)
 */
/**
 * Provides natural content overflow behavior and scrolling support
 * even so for touch devices.
 *
 * Example usage:
 * @include overflow()
 */
/**
 * Neutralizes/resets dimensions including width, height, position as well as margins,
 * paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
 *
 * Example usage:
 * @include clear(true)
 *
 * @param boolean $important
 */
@-webkit-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-moz-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
#lightcase-case {
  display: none;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-case, html[data-lc-type=ajax] #lightcase-case {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    padding: 55px 0 70px 0;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
  }
}

@media screen and (min-width: 641px) {
  html:not([data-lc-type=error]) #lightcase-content {
    position: relative;
    z-index: 1;
    text-shadow: none;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -webkit-backface-visibility: hidden;
  }
}
@media screen and (min-width: 641px) {
  html[data-lc-type=image] #lightcase-content, html[data-lc-type=video] #lightcase-content {
    background-color: #333;
  }
}
html[data-lc-type=inline] #lightcase-content, html[data-lc-type=ajax] #lightcase-content, html[data-lc-type=error] #lightcase-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content, html[data-lc-type=ajax] #lightcase-content, html[data-lc-type=error] #lightcase-content {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: none !important;
  }
}
html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner, html[data-lc-type=error] #lightcase-content .lightcase-contentInner {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner, html[data-lc-type=error] #lightcase-content .lightcase-contentInner {
    padding: 15px;
  }
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=inline] #lightcase-content .lightcase-contentInner > *, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner > *, html[data-lc-type=error] #lightcase-content .lightcase-contentInner, html[data-lc-type=error] #lightcase-content .lightcase-contentInner > * {
    width: 100% !important;
    max-width: none !important;
  }
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner > *:not(iframe), html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner > *:not(iframe), html[data-lc-type=error] #lightcase-content .lightcase-contentInner > *:not(iframe) {
    height: auto !important;
    max-height: none !important;
  }
}
@media screen and (max-width: 640px) {
  html.lightcase-isMobileDevice[data-lc-type=iframe] #lightcase-content .lightcase-contentInner iframe {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (max-width: 640px) and (min-width: 641px) {
  html[data-lc-type=image] #lightcase-content .lightcase-contentInner, html[data-lc-type=video] #lightcase-content .lightcase-contentInner {
    line-height: 0.75;
  }
}
html[data-lc-type=image] #lightcase-content .lightcase-contentInner {
  position: relative;
  overflow: hidden !important;
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap, html[data-lc-type=error] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: none !important;
  }
}
@media screen and (min-width: 641px) {
  html:not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    padding: 30px;
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 640px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #aaa;
  }
}
@media screen and (min-width: 641px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #333;
  }
}

#lightcase-case p.lightcase-error {
  margin: 0;
  font-size: 17px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  #lightcase-case p.lightcase-error {
    padding: 30px 0;
  }
}
@media screen and (min-width: 641px) {
  #lightcase-case p.lightcase-error {
    padding: 0;
  }
}

.lightcase-open body {
  overflow: hidden;
}
.lightcase-isMobileDevice .lightcase-open body {
  max-width: 100%;
  max-height: 100%;
}

#lightcase-info {
  position: absolute;
  padding-top: 15px;
}
#lightcase-info #lightcase-title,
#lightcase-info #lightcase-caption {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-weight: normal;
  text-overflow: ellipsis;
}
#lightcase-info #lightcase-title {
  font-size: 17px;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  #lightcase-info #lightcase-title {
    position: fixed;
    top: 10px;
    left: 0;
    max-width: 87.5%;
    padding: 5px 15px;
    background: #333;
  }
}
#lightcase-info #lightcase-caption {
  clear: both;
  font-size: 13px;
  color: #aaa;
}
#lightcase-info #lightcase-sequenceInfo {
  font-size: 11px;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  .lightcase-fullScreenMode #lightcase-info {
    padding-left: 15px;
    padding-right: 15px;
  }
  html:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-info {
    position: static;
  }
}

#lightcase-loading {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  opacity: 1;
  font-size: 32px;
  text-shadow: 0 0 15px #fff;
  -moz-transform-origin: 50% 53%;
  -webkit-animation: lightcase-spin 0.5s infinite linear;
  -moz-animation: lightcase-spin 0.5s infinite linear;
  -o-animation: lightcase-spin 0.5s infinite linear;
  animation: lightcase-spin 0.5s infinite linear;
}
#lightcase-loading, #lightcase-loading:focus {
  text-decoration: none;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
#lightcase-loading > span {
  display: inline-block;
  text-indent: -9999px;
}

a[class*=lightcase-icon-] {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  outline: none;
  cursor: pointer;
}
a[class*=lightcase-icon-], a[class*=lightcase-icon-]:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
a[class*=lightcase-icon-] > span {
  display: inline-block;
  text-indent: -9999px;
}
a[class*=lightcase-icon-]:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 15px rgb(255, 255, 255);
}
.lightcase-isMobileDevice a[class*=lightcase-icon-]:hover {
  color: #aaa;
  text-shadow: none;
}
a[class*=lightcase-icon-].lightcase-icon-close {
  position: fixed;
  top: 15px;
  right: 15px;
  bottom: auto;
  margin: 0;
  opacity: 0;
  outline: none;
}
a[class*=lightcase-icon-].lightcase-icon-prev {
  left: 15px;
}
a[class*=lightcase-icon-].lightcase-icon-next {
  right: 15px;
}
a[class*=lightcase-icon-].lightcase-icon-pause, a[class*=lightcase-icon-].lightcase-icon-play {
  left: 50%;
  margin-left: -0.5em;
}
@media screen and (min-width: 641px) {
  a[class*=lightcase-icon-].lightcase-icon-pause, a[class*=lightcase-icon-].lightcase-icon-play {
    opacity: 0;
  }
}
@media screen and (max-width: 640px) {
  a[class*=lightcase-icon-] {
    bottom: 15px;
    font-size: 24px;
  }
}
@media screen and (min-width: 641px) {
  a[class*=lightcase-icon-] {
    bottom: 50%;
    margin-bottom: -0.5em;
  }
  a[class*=lightcase-icon-]:hover, #lightcase-case:hover ~ a[class*=lightcase-icon-] {
    opacity: 1;
  }
}

#lightcase-overlay {
  display: none;
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 2000;
  top: -9999px;
  bottom: -9999px;
  left: 0;
  background: #333;
}
@media screen and (max-width: 640px) {
  #lightcase-overlay {
    opacity: 1 !important;
  }
}