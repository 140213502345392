.branch-detail
    position: relative

.branch-detail__info
    position: relative
    display: grid
    grid-template-columns: 1fr 1.5fr
    gap: 5rem
    padding-bottom: 16rem

    @media screen and (max-width: $md)
        grid-template-columns: 1fr
        gap: 3rem

    @media screen and (max-width: $sm)
        padding-bottom: 10rem

    &::before
        content: ''
        position: absolute
        bottom: 8rem
        left: 0
        width: 100%
        height: 1px
        background-color: rgba($dark,.5)

        @media screen and (max-width: $sm)
            bottom: 5rem

.branch-detail__sub
    display: flex
    flex-direction: column
    gap: 3rem
    margin-bottom: 5rem

.branch-detail__info__left

    span
        display: block
        color: $primary
        font-size: 1.8rem
        font-weight: 700
        text-transform: uppercase
        margin-bottom: 1rem

        @media screen and (max-width: $xs)
            font-size: 1.6rem

.branch-detail__info__adress
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 2rem

    @media screen and (max-width: $xs)
        grid-template-columns: 1fr

.branch-detail__info__adress__item
    display: flex
    flex-direction: column
    gap: .8rem

    p
        position: relative
        padding-left: 2.5rem
        max-width: 19rem

        &::before
            content: ''
            position: absolute
            top: .5rem
            left: 0
            width: 2rem
            height: 2rem
            background-image: url('../img/icon/marker.svg')

.branch-detail__info__right
    font-size: 1.6rem
    line-height: 1.6

    a
        color: $dark

.branch-detail__text
    margin-bottom: 5rem

.branch-detail__gallery__images
    position: relative
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 2rem
    padding-bottom: 16rem

    @media screen and (max-width: $md)
        grid-template-columns: repeat(3, 1fr)

    @media screen and (max-width: $sm)
        padding-bottom: 10rem

    @media screen and (max-width: $xs)
        grid-template-columns: repeat(2, 1fr)
        gap: 1rem

    &::before
        content: ''
        position: absolute
        bottom: 8rem
        left: 0
        width: 100%
        height: 1px
        background-color: rgba($dark,.5)

        @media screen and (max-width: $sm)
            bottom: 5rem

    a
        position: relative
        display: block
        width: 100%

        &::after
            content: ''
            position: absolute
            inset: 0
            width: 100%
            height: 100%
            background-color: rgba($primary,.5)
            opacity: 0
            transition: opacity 250ms ease-in-out

        &::before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 4rem
            height: 4rem
            background-image: url('../img/icon/search.svg')
            background-size: contain
            opacity: 0
            z-index: 2
            transition: opacity 250ms ease-in-out

        &:hover
            &::after,
            &::before
                opacity: 1

    img
        display: block
        width: 100%
        object-fit: cover
        aspect-ratio: 1 / 1

.branch-detail__services
    position: relative
    padding-bottom: 16rem

    @media screen and (max-width: $sm)
        padding-bottom: 10rem

    &::before
        content: ''
        position: absolute
        bottom: 8rem
        left: 0
        width: 100%
        height: 1px
        background-color: rgba($dark,.5)

        @media screen and (max-width: $sm)
            bottom: 5rem

.branch-detail__services__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 1rem

    @media screen and (max-width: $md)
        grid-template-columns: repeat(2, 1fr)

    @media screen and (max-width: $sm)
        grid-template-columns: 1fr

    li
        display: flex
        align-items: center
        gap: 1rem
        height: 100%
        padding: 1.8rem
        background-color: $white

.branch-detail__pages
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 4rem

    @media screen and (max-width: $lg)
        gap: 2rem

    @media screen and (max-width: $sm)
        grid-template-columns: repeat(2, 1fr)
        gap: 1rem

.branch-detail__pages__item
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    gap: 1rem
    text-decoration: none
    background-color: $white
    padding: 2.4rem 3rem
    border: 2px solid $white
    transition: border-color 250ms ease-in-out

    &:hover
        border-color: $primary

    img
        width: 100%
        max-width: 5.7rem
        object-fit: cover

    @media screen and (max-width: $sm)
        padding: 2rem 1rem

        img
            max-width: 4rem
