.collection-main
    position: relative
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    gap: 2.6rem
    padding: 7rem 0

    @media screen and (max-width: $lg)
        grid-template-columns: 1fr

    @media screen and (max-width: $lg)
        gap: 0

    @media screen and (max-width: $xs)
        padding: 5rem 0

.collection-main__image
    height: 100%

    @media screen and (max-width: $lg)
        aspect-ratio: 2.5 / 1

    @media screen and (max-width: $xs)
        aspect-ratio: 1.5 / 1

    img
        display: block
        width: 100%
        height: 100%
        object-fit: cover

.collection-main__info
    display: flex
    flex-direction: column
    justify-content: center
    height: 100%
    background-color: rgba($dark,.1)
    padding: 5rem 12rem

    @media screen and (max-width: $xl)
        padding: 5rem 6rem

    @media screen and (max-width: $md)
        padding: 5rem 3rem

    @media screen and (max-width: $xs)
        padding: 4rem 2rem

    span
        display: block
        color: $primary
        font-size: 1.8rem
        font-weight: 700
        text-transform: uppercase
        margin-bottom: 1.4rem

        @media screen and (max-width: $xs)
            font-size: 1.6rem

.collection-main__info__text
    max-width: 55rem
    margin-bottom: 2rem


.collection-main__wrapper
    display: flex
    gap: 16px
