.nav__submenu
    display: flex
    flex-direction: column
    gap: 16px

    & > .nav__sub-item
        border: 1px solid $black
        padding: 16px

        &.--child-active,
        .--active
            & > .nav__sub-link
                font-weight: 700

        & > .nav__sub-link
            display: table

.nav__sub-list
    display: flex
    flex-direction: column
    gap: 16px

    &.nest-depth--1,
    &.nest-depth--2
        padding-left: 8px
        display: none

.nav__sub-item
    display: flex
    flex-direction: column
    gap: 4px

    &.--child-active,
    .--active
        & > .nav__sub-list
            display: block

.nav__sub-link
    transition: all 250ms ease-in-out
    color: $dark

    &:hover
        padding-left: 8px
