.branch
    position: relative

.branch__text
    position: relative
    display: grid
    grid-template-columns: 1fr 1.5fr
    align-items: center
    gap: 5rem
    margin-bottom: 10rem

    @media screen and (max-width: $md)
        grid-template-columns: 1fr
        gap: 2.4rem

    @media screen and (max-width: $sm)
        margin-bottom: 6rem

    &::before
        content: ''
        position: absolute
        bottom: -5rem
        left: 0
        width: 100%
        height: 1px
        background-color: $dark

        @media screen and (max-width: $sm)
            bottom: -3.5rem

.branch__list
    display: flex
    flex-direction: column
    gap: 10rem

    @media screen and (max-width: $sm)
        gap: 6rem

    .branch__item
        &:last-child
            &::before
                display: none

.branch__item
    position: relative
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    gap: 6.6rem

    @media screen and (max-width: $md)
        gap: 4.6rem

    @media screen and (max-width: $sm)
        grid-template-columns: 1fr
        gap: 2.6rem

    &::before
        content: ''
        position: absolute
        bottom: -5rem
        left: 0
        width: 100%
        height: 1px
        background-color: $dark

        @media screen and (max-width: $sm)
            bottom: -3.5rem

    &:nth-child(even)
        .branch__info
            order: 2

            @media screen and (max-width: $sm)
                order: initial

.branch__info
    display: flex
    flex-direction: column
    gap: 3.2rem

    @media screen and (max-width: $sm)
        gap: 2.4rem

.branch__image
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 2.2rem

    @media screen and (max-width: $md)
        grid-template-columns: 1fr

        picture
            &:last-child
                display: none

        img
            aspect-ratio: 1 / 1

    @media screen and (max-width: $sm)
        img
            aspect-ratio: 2 / 1

    img
        width: 100%
        height: 100%
        object-fit: cover
        aspect-ratio: 1 / 1.25
