.newsletter
    display: flex
    align-items: center
    background-color: $primary
    padding: 60px 40px
    flex-wrap: wrap

    @media (max-width: $sm)
        padding: 30px 20px

.newsletter__content
    width: calc(50% - 40px)
    margin-right: 40px
    color: #fff
    @media (max-width: $sm)
        width: 100%
        margin-bottom: 20px
        margin-right: 0

.newsletter__title
    color: #fff

.newsletter__form
    width: 50%

    @media (max-width: $sm)
        width: 100%

.newsletter__form-wrapper
    display: flex
    background-color: #fff
    justify-content: space-between
    padding: 10px
    position: relative
    margin-bottom: 12px
    align-items: center

    .btn
        border: 1px solid $primary !important
        &:hover
            border: 1px solid $primary

    input[type='email']
        border: none
        width: 500px
        max-width: 100%
        border: unset
        padding-left: 20px

    .validation
        position: absolute
        top: calc(100% + 10px)
        color: red

    .btn-std
        margin: 0
        padding: 24px 45px
        border: 2px solid #fff

    .newsletter_success
        padding: 20px 10px
        background-color: #28B231
        text-align: center
        color: #fff
        width: 100%
        border-radius: 50px

    @media (max-width: $sm)
        padding: 0 0.5rem
        align-items: center

        input[type='email']
            width: 190px
            flex-grow: 1
            padding: 20px 12px

.ecomail__gdpr
    span

        color: #fff
        line-height: 1.4
        text-align: center
        display: block
