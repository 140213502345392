html
    font-size: 62.5%
    font-family: $ff-primary

html:focus-within
    scroll-behavior: smooth

body
    font-size: 1.6rem
    line-height: $line-height
    padding-top: $body-padding
    overflow-x: hidden
    background-color: $secondary

    @media screen and (max-width: $lg)
        padding-top: 9.5rem

    @media screen and (max-width: $xs)
        padding-top: 8.2rem

    &.is-fixed
        overflow: hidden

a
    &:focus
        outline: none

    &:focus-visible
        outline: 2px solid $dark

button
    &:focus
        outline: none

    &:focus-visible
        outline: 2px solid $dark

ul
    list-style: none

img
    height: auto
    max-width: 100%
