.header
    position: fixed
    top: 0
    width: 100%
    max-width: 1920px
    z-index: 99
    padding: 1rem 0
    background-color: $white
    transition: padding 250ms ease-in-out

    // &.is-scroll

.header__content
    display: flex
    justify-content: space-between
    align-items: center
    gap: 3rem

.header__logo

    @media screen and (max-width: $lg)
        max-width: 11rem

    @media screen and (max-width: $xs)
        max-width: 9rem

