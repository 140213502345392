.collection
    position: relative

.collection__list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 2rem

    @media screen and (max-width: $lg)
        grid-template-columns: repeat(3, 1fr)

    @media screen and (max-width: $sm)
        grid-template-columns: repeat(2, 1fr)
        gap: 1rem

.collection__item
    display: block
    text-decoration: none
    color: $dark
    transition: background-color 250ms ease-in-out

    &:hover
        background-color: $primary
        text-decoration: none !important

        .collection__text
            h2
                color: $white

.collection__text

    h2
        padding: 1.5rem
        transition: color 250ms ease-in-out

.collection__image

    img
        display: block
        width: 100%
        object-fit: cover
        aspect-ratio: 2 / 1

        @media screen and (max-width: $sm)
            aspect-ratio: 1.5 / 1

