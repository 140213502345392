form,
.form
    width: 100%
    margin: 0 auto

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    select,
    textarea
        width: 100%
        height: 5rem
        padding: 1rem 2rem
        line-height: 1.3rem
        border: 2px solid $gray-100
        border-radius: 1rem
        outline: none
        background-color: $white
        color: $dark
        font-size: 1.6rem
        font-size: fz(16px,18px)

        &::placeholder
            color: $gray-200
            font-size: 14px

        &:active,
        &:focus,
        &:focus-visible
            border-color: $dark

    textarea
        padding: 2rem
        min-height: 12rem
        line-height: 1.3em

.form__item
    display: grid
    column-gap: 2rem
    row-gap: 0
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
    max-width: 100%

.form-group
    position: relative
    margin-bottom: 3rem

    &.mb-sm
        margin-bottom: .5rem

    &.has-error
        input
            border-color: $error

            &::placeholder
                color: $error

    span
        display: block
        color: $primary
        background-color: transparent
        margin-bottom: 1rem

    & .validation
        position: absolute
        top: calc(100% - 1px)
        font-size: 14px
        color: $error
        letter-spacing: 1px
        margin: .5rem 0 0 .5rem

.form__intro
    display: block
    color: $primary
    font-weight: 700

.form__label
    &.--checkbox
        cursor: pointer

        input[type="checkbox"]
            position: absolute
            opacity: 0

            & + span
                position: relative
                display: inline-block
                color: $dark
                padding-left: 3rem
                margin: 0

                &:after,
                &::before
                    content: ''
                    position: absolute
                    top: 0
                    left: 0
                    width: 1.3em
                    height: 1.3em
                    border-radius: 4px
                    transition: all 250ms ease-in-out

                &:after
                    background-color: transparent
                    border: 1px solid $primary
                    opacity: 1

                &::before
                    background-position: center
                    background-size: contain

                &:hover
                    &::before
                        background-image: url(../img/icon/check-form.svg)

        input[type="checkbox"]
            &:checked
                & + span
                    &:after
                        background-color: rgba($primary,.7)

                    &::before
                        background-image: url(../img/icon/check-form.svg)
                        z-index: 1

        a
                color: $dark
                display: inline-block

                &:hover
                    text-decoration: none

#form__inputs
    display: flex
    width: 100%
    max-width: 75rem
    margin: 0 auto
