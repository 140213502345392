.nav
    font-family: $ff-secondary
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    @media screen and (max-width: $lg)
        flex-direction: column
        position: absolute
        top: calc(100% - 1px)
        left: 0
        height: 100vh
        width: 100%
        background-color: $white
        opacity: 0
        visibility: hidden
        transform: translateY(-15px)
        transition: all 250ms ease-in-out
        overflow-y: auto
        z-index: 999

        ul
            flex-direction: column
            justify-content: center

        &.is-open
            padding: 1rem 0 15rem 0
            opacity: 1
            visibility: visible
            transform: translateY(0)

.nav__info
    display: flex
    justify-content: space-between
    width: 100%
    max-width: 100.5rem
    margin-left: auto
    padding-bottom: .8rem
    border-bottom: 1px solid rgba($dark,.5)

    @media screen and (max-width: $lg)
        flex-direction: column
        justify-content: center
        align-items: center
        margin: initial
        max-width: 100%

.nav__social
    display: flex
    gap: 1rem

    @media screen and (max-width: $lg)
        margin-bottom: 1rem

.nav__links
    display: flex
    align-items: center

    a
        display: block
        color: $primary
        text-decoration: none
        font-size: 1.4rem
        padding: .6rem 1rem

        &:hover
            text-decoration: underline

.nav__list
    display: flex
    width: 100%
    justify-content: flex-end

.nav__list.nest-depth--1,
.nav__list.nest-depth--2,
.nav__list.nest-depth--3
    & > .nav__item
            &.has-submenu
                span,
                ul
                    display: none

.nav__item
    display: flex

    @media screen and (max-width: $lg)
        display: block

    &.has-submenu
        position: relative

        &:last-child
            ul
                left: unset
                right: 0

        a
            padding-right: 3rem

            &::after
                display: none

        ul
            position: absolute
            min-width: 26rem
            background-color: $white
            visibility: hidden
            opacity: 0
            left: 0
            top: 100%
            transform: translateY(-25px)
            transition: all 250ms
            flex-direction: column

            &.nest-depth--1
                justify-content: unset

            &.nest-depth--2,
            &.nest-depth--3,
            &.nest-depth--4
                justify-content: unset
                right: unset
                opacity: 0!important

                &.is-open
                    opacity: 1!important

            li
                flex-grow: 1

                a
                    position: relative
                    display: block
                    width: 100%
                    color: $dark
                    padding: .8rem 2rem
                    text-decoration: none
                    overflow: hidden
                    font-size: 1.8rem
                    transition: color 250ms ease-in-out
                    text-align: left
                    white-space: initial

                    @media screen and (max-width: $lg)
                        font-size: 1.6rem
                        padding: .8rem 2rem .8rem 5rem

                    &:hover
                        color: $primary

        span
            @media screen and (min-width: $lg-m)

                &:after
                    content: ''
                    position: absolute
                    top: 50%
                    right: 0
                    transform: translateY(-50%)
                    width: 30px
                    height: 30px
                    background-image: url('../img/icon/v-down.svg')
                    background-position: center
                    transition: transform 250ms

            @media screen and (max-width: $lg)
                display: inline-block
                position: absolute
                top: 1rem
                right: 2rem
                width: 5.5rem
                height: 4.5rem
                cursor: pointer
                background-color: $primary

                &:before
                    content: ''
                    position: absolute
                    top: 12px
                    right: 17px
                    transform: scale(.9)
                    width: 2.2rem
                    height: 2.2rem
                    background-image: url('../img/icon/v-down.svg')
                    transition: transform 250ms ease-in-out

                &:hover
                    border-color: $primary

            @media screen and (max-width: $xs)
                top: 1rem

        &:hover,
        &.is-open

            @media screen and (min-width: $lg-m)
                ul
                    transform: translateY(0)
                    visibility: visible
                    opacity: 1

        &.is-open

            @media screen and (max-width: $lg)
                ul
                    position: relative
                    background-color: $dark
                    transform: translateY(0)
                    visibility: visible
                    opacity: 1

                span
                    border-color: $dark

.nav__link
    position: relative
    display: table
    color: $dark
    padding: 1.4rem 2rem
    text-decoration: none
    overflow: hidden
    font-size: 1.6rem
    text-align: center
    white-space: nowrap

    @media screen and (max-width: $lg)
        position: relative
        padding: 2rem
        width: 100%
        margin: 0 auto
        text-align: left

.nav__item
    &.--active,
    &.--child-active
        a
            color: $primary

    .nav__list

        &.is-open

            @media screen and (max-width: $lg)
                position: relative
                background-color: $white
                transform: translateY(0)
                visibility: visible
                opacity: 1
                left: unset
                top: unset

                span
                    border-color: $dark
