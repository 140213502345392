a.fill__link
    position: absolute
    display: block
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 3

.btn
    position: relative
    display: block
    width: fit-content
    height: fit-content
    padding: 1.6rem 3.6rem
    color: $white
    background-color: $primary
    text-align: center
    border: none
    outline: none
    cursor: pointer
    text-decoration: none
    transition: all 250ms
    font-size: 1.8rem

    @media screen and (max-width: $sm)
        font-size: 1.6rem
        padding: 1.2rem 2.4rem

    &:hover
        color: $white
        background-color: $primary-hover

    &.--center
        margin: 0 auto
