.page-detail__title
    margin-bottom: 16px

.page__bg-img
    img
        width: 100%
        height: 30rem
        object-fit: cover
        display: block

        @media screen and (max-width: $xs)
            height: 20rem

