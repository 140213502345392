//Files default style
a[href$=".pdf"]
    &::before
        background-image: url("../img/files/pdf.svg")

a[href$=".doc"],
a[href$=".docx"]
    &::before
        background-image: url("../img/files/word.svg")

a[href$=".xlsx"],
a[href$=".xls"]
    &::before
        background-image: url("../img/files/excel.svg")

a[href$=".jpg"],
a[href$=".jpeg"],
a[href$=".png"],
a[href$=".svg"]
    &::before
        background-image: url("../img/files/jpg.svg")

.files__wrapper
    display: flex
    flex-wrap: wrap
    gap: 16px
    margin-bottom: 32px

.files__item
    width: calc(50% - 16px)
    border: 1px solid $dark
    padding: 16px
    background-color: $white
    display: flex
    align-items: center
    transition: all 250ms ease-in-out
    position: relative
    color: $dark

    a,
    div
        color: $dark
        text-decoration: none

    &:hover
        transform: translate(0, -5px)
        box-shadow: 0 7px 5px 0 rgba(0, 0, 0, .2)

    @media (max-width: $md)
        width: 100%

.files__item-file-type
    width: 100%
    position: relative
    padding: 0 0 0 50px
    font-weight: 700
    word-break: break-word

    &::before
        position: absolute
        content: ""
        top: 50%
        left: 0
        width: 30px
        height: 30px
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        transform: translate(0, -50%)
