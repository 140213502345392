.hamburger
    position: relative
    display: none
    width: 3rem
    height: 3rem
    cursor: pointer
    z-index: 9999

    @media screen and (max-width: $lg)
        display: block

    input
        display: none

    &:before,
    &:after,
    span
        content: ''
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        width: 3rem
        height: .4rem
        background-color: $dark
        border-radius: 3rem
        opacity: 1
        transition: transform 250ms

    &:before
        top: 2.4rem

    &:after
        top: .6rem

    span
        top: 50%
        transition: opacity 250ms

    &.is-active

        &:before
            top: 1.2rem
            transform: translateX(-50%) rotate(-45deg)

        &:after
            top: 1.2rem
            transform: translateX(-50%) rotate(45deg)

        span
            opacity: 0
