// Custom Reboot
*,
*::before,
*::after
    margin: 0
    padding: 0
    background-repeat: no-repeat
    box-sizing: border-box

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote
    margin: 0

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce)
    html:focus-within
        scroll-behavior: auto

    *,
    *::before,
    *::after
        animation-duration: 0.01ms !important
        animation-iteration-count: 1 !important
        transition-duration: 0.01ms !important
        scroll-behavior: auto !important
