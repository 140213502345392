
.ss-result
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid $dark

    h4
        display: flex
        align-items: center
        gap: 16px
        position: relative
        font-weight: 700

        a
            color: $dark
            text-decoration: none

            &:hover
                text-decoration: underline

    mark
        font-weight: 600
        display: inline-block
        padding: 2px 6px
        border-radius: 6px

    p
        margin-bottom: 8px

    &__badge
        display: inline-block
        font-size: 11px
        padding: 6px 11px
        background-color: $primary
        color: $white
        margin-left: 10px
        border-radius: 8px
        vertical-align: top

    &__link
        font-weight: 600
        position: relative
        color: $primary
        text-decoration: none
        &:hover
            text-decoration: underline

.ss-result__title

    @media (max-width: $sm)
        display: flex
        justify-content: space-between

.search-modal
    position: fixed
    width: 100%
    height: 0
    overflow: hidden
    top: 0
    left: 0
    z-index: 1080
    background: $secondary
    justify-content: center
    align-items: center
    display: none
    transition: all 250ms ease-in-out

    &.shown
        display: flex
        animation: toFullHeight 300ms ease-in-out forwards

    input
        background-color: $white
        font-size: 3.8rem
        font-weight: 700
        padding: 30px 40px
        border: none
        border-radius: 60px
        margin-bottom: 20px

        @media (max-width: $sm)
            display: table
            width: 100%
            max-width: 100%
            font-size: 2.4rem
            padding: 20px 25px

        @media (max-width: $xs)
            font-size: 1.8rem

.close-modal.close-modal-cross
    position: absolute
    width: 80px
    height: 80px
    display: block
    border: 3px solid $primary
    top: 30px
    right: 30px
    border-radius: 50%

    @media (max-width: $sm)
        width: 50px
        height: 50px

    &:before,
    &:after
        content: ""
        position: absolute
        width: 60%
        height: 60%
        top: 50%
        left: 50%
        height: 3px
        display: block
        background-color: $primary
        transform: translate(-50%, -50%) rotate(45deg)

    &:after
        transform: translate(-50%, -50%) rotate(-45deg)

.ss-search-form__results.ss-search-form__results--visible
    padding: 16px
    background: $white
    border-radius: 10px
    margin-bottom: 20px

    @media (max-width: $sm)
        padding: 16px 16px 8px

.ss-autocomplete-result
    font-size: 2.2rem
    font-weight: 700
    text-decoration: none

    &__badge
        display: inline-block
        background-color: $primary
        font-size: 1.4rem
        letter-spacing: .75px
        color: $white
        padding: 4px 12px
        border-radius: 6px
        margin-left: 10px

.ss-autocomplete-result__title
    padding: .5rem 0

    a
        color: $dark
        text-decoration: none

.ss-autocomplete-result__content
    padding-bottom: 8px
    margin-top: 8px

.ss-show-all-results
    padding: 8px 0

    a
        color: $primary

.ss-result
    p
        margin: 1rem 0

@keyframes toFullHeight
    0%
        height: 0

    100%
        height: 100%
